export const CAUSES_OF_REJECTION_LABELS =
  {
    'CHANGE_BANK_DETAILS': 'Ajustar dados bancários',
    'CHANGE_PERSONAL_DATA': 'Ajustar dados pessoais',
    'CHANGE_ADDRESS': 'Ajustar endereço',
    'CHANGE_PROPOSAL_INSTALLMENTS_QUANTITY': 'Ajustar quantidade de parcelas da proposta',
    'CHANGE_GROSS_SALARY': 'Ajustar salário bruto',
    'CHANGE_NET_SALARY': 'Ajustar salário líquido',
    'CHANGE_LOAN_PROPOSAL_AMOUNT': 'Ajustar valor da proposta de empréstimo',
    'FACIAL_BIOMETRY_NOT_PERFORMED': 'Biometria Facial - Não realizada',
    'FACIAL_BIOMETRY_FAILED': 'Biometria Facial - Reprovada',
    'CUSTOMER_HAS_GIVEN_UP_ON_THE_PROPOSAL': 'Cliente desistiu da proposta',
    'INELIGIBLE_CUSTOMER_ENGINE_ANALYSIS': 'Cliente não elegível - Análise motor de elegibilidade',
    'INELIGIBLE_CUSTOMER_INSUFFICIENT_HOME_TIME': 'Cliente não elegível - Tempo de casa insuficiente',
    'INELIGIBLE_CUSTOMER_CPF_CANCELED': 'Cliente não elegível - CPF com situação Cancelada na Receita Federal',
    'INELIGIBLE_CUSTOMER_CPF_NULL': 'Cliente não elegível - CPF com situação Nula na Receita Federal',
    'INELIGIBLE_CUSTOMER_CPF_SUSPENDED': 'Cliente não elegível - CPF com situação Suspensa na Receita Federal',
    'INELIGIBLE_CUSTOMER_AGE_RANGE': 'Cliente não elegível - Faixa etária',
    'MISSING_CUSTOMER_FINAL_INFORMATIONS': 'Cliente não enviou informações finais',
    'CUSTOMER_FOLLOWED_UP_WITH_COMPETITIOR': 'Cliente seguiu com concorrente',
    'UNLIMITED_CUSTOMER_AVAILABLE': 'Cliente sem limite disponível',
    'EMPLOYEE_AWAY_FROM_WORK': 'Cliente afastado do trabalho',
    'EMPLOYEE_DISMISSED_FROM_WORK': 'Cliente desligado do trabalho',
    'CUSTOMER_DID_NOT_UPDATE_BANK_DETAILS': 'Cliente não atualizou dados bancários',
    'CUSTOMER_DEBTS_NEGATIVES': 'Cliente com dívidas e negativações',
    'CUSTOMER_HAS_GIVEN_UP_ON_THE_PROPOSAL_OTHER_REASONS': 'Cliente desistiu da proposta - Outros motivos',
    'CUSTOMER_HAS_GIVEN_UP_ON_THE_PROPOSAL_OPTED_THE_COMPETITOR': 'Cliente desistiu da proposta - Optou pelo concorrente',
    'CUSTOMER_HAS_GIVEN_UP_ON_THE_PROPOSAL_VALUE_DOESNT_MEET': 'Cliente desistiu da proposta - Valor não atende',
    'CUSTOMER_OF_WORK': 'Cliente desligado do trabalho',
    'DOESNT_UPDATE_PERSONAL_DATA': 'Cliente não atualizou dados pessoais',
    'INELIGIBLE_CUSTOMER_AGE_RANGE_ABOVE_SIXTY': 'Cliente não elegível - Faixa etária +60 Anos',
    'INELIGIBLE_CUSTOMER_AGE_RANGE_ABOVE_SIXTY_FIVE': 'Cliente não elegível - Faixa etária +65 anos',
    'CUSTOMER_WITHOUT_MANDATORY_COURSE': 'Cliente sem curso obrigatório',
    'CUSTOMER_NO_CONSIGNABLE_MARGIN': 'Cliente sem margem consignável',
    'EMPLOYEE_AWAY': 'Colaborador afastado',
    'EMPLOYEE_DISMISSED': 'Colaborador desligado',
    'EMPLOYEE_PASSED_AWAY': 'Colaborador faleceu',
    'EMPLOYEE_WILL_BE_RETIRED': 'Colaborador será afastado',
    'EMPLOYEE_WILL_BE_DISMISSED': 'Colaborador será desligado',
    'DEBT_PURCHASE_REFUSED_BY_THE_CUSTOMER': 'Compra de dívida - Recusado pelo cliente',
    'PURCHASE_AND_EXCHANGE_DEBT': 'Compra de dívida e troco - Sem limite para operação',
    'PROOF_OF_ADDRESS_MISSING': 'Comprovante de endereço - Faltando',
    'PROOF_OF_ADDRESS_INAPPROPRIATE': 'Comprovante de endereço - Inadequado',
    'PROOF_OF_ADDRESS_NAME_OF_THIRD_PARTIES': 'Comprovante de endereço - Em nome de terceiros (avôs/irmãos/padrastos)',
    'INCORRECT_DATA': 'Dados incorretos/inconsistentes',
    'COMPANY_DOES_NOT_RELEASE_MORE_THAN_ONE_LOAN_PER_EMPLOYEE': 'Empresa não libera mais de um empréstimo por colaborador',
    'BRANCH_WITHOUT_ACTIVE_AGREEMENT': 'Filial sem convênio ativo',
    'OUTSIDE_THE_RULES_IDENTIFICATION_DOCUMENT': 'Fora das regras - Documento de identificação',
    'OUTSIDE_THE_RULES_PAY_STUB': 'Fora das regras - Holerite',
    'OUTSIDE_THE_RULES_PAY_STUB_AND_IDENTIFICATION_DOCUMENT': 'Fora das regras - Documento de identificação/Holerite',
    'PAY_STUB_AND_PROOF_OF_ADDRESS_INADEQUATE_MISSING': 'Holerite e Comprovante de endereço - Inadequado/Faltando',
    'PAY_STUB_INADEQUATE_MISSING': 'Holerite - Inadequado/Faltando',
    'SPECIAL_OFFER_REJECTED': 'Oferta especial reprovada',
    'DEADLINE_FOR_REGISTRATION_EXPIRED': 'Prazo para averbação expirado',
    'RG_PAY_STUB_AND_PROOF_OF_ADDRESS_INADEQUATE_MISSING':'Rg, Holerite e Comprovante de endereço - Inadequado/Faltando',
    'INAPPROPRIATE_RG_CHILD_PHOTO': 'Rg - Inadequado - Foto infantil',
    'INAPPROPRIATE_RG_MISSING': 'Rg - Inadequado/Faltando',
    'INADEQUATE_MISSING_RG_AND_PROOF_OF_RESIDENCE': 'Rg e Comprovante de endereço - Inadequado/Faltando',
    'RG_AND_PAY_STUB_INADEQUATE_MISSING': 'Rg e Holerite - Inadequado/Faltando',
    'RESEND_PROOF_OF_ADDRESS': 'Reenviar comprovante de endereço',
    'RESEND_PROOF_OF_INCOME': 'Reenviar comprovante de renda',
    'RESEND_ID': 'Reenviar documento de identificação',
    'REFINANCING_CUSTOMER_NOT_ELIGIBLE': 'Refinanciamento - cliente não elegível',
    'REFINANCING_REFUSED_BY_THE_CUSTOMER': 'Refinanciamento - recusado pelo cliente',
    'REFINANCING_AND_EXCHANGE_AMOUNT_NOT_AVAILABLE': 'Refinanciamento e troco - valor não disponível para operação',
    'COMPANY_RULE_EXCEEDED_CONTRACT_LIMIT': 'Regra empresa - Limite de contrato excedido',
    'COMPANY_RULE_INTERNAL_OBLIGATIONS_NOT_FULFILLED': 'Regra empresa - Obrigatoriedades internas não atendidas',
    'FOLLOWED_WITH_ANOTHER_PROPOSAL': 'Seguiu com outra proposta',
    'CUSTOMER_UNREACHABLE': 'Sem contato com cliente',
    'NO_CONSIGNABLE_MARGIN': 'Sem margem consignável',
    'NO_MAXIMUM_AMOUNT_AVAILABLE_FOR_OPERATION': 'Sem valor máximo disponível para operação',
    'PLATFORM_TEST': 'Teste da plataforma',
    'INSUFFICIENT_HOME_TIME': 'Tempo de casa insuficiente',
    'PAY_STUB_VALIDATION_NO_OPERATION_LIMIT': 'Validação holerite - Sem limite para operação',
    'REQUESTED_VALUE_TOO_HIGH': 'Valor solicitado acima do disponível',
    "DOESNT_NEED_THE_MONEY_ANYMORE": "Não preciso mais do valor",
    "REGISTERED_SALARY_ACCOUNT_BUT_DOESNT_HAVE_INTEREST": "Cadastrei conta salário e não tenho interesse em abrir ou enviar nova conta",
    "DOESNT_RECEIVED_THE_VALUE": "Não recebi o valor e não tenho mais interesse no empréstimo",
    "LOAN_PROCESS_SLOW": "Processo de empréstimo demorado",
    "RELEASED_AMOUNT_DOESNT_MEET_LOAN_PURPOSE": "O valor liberado não atende o objetivo do empréstimo",
    "OPERATIONAL_PAUSE": "Pausa operacional",
    "OTHER": "",
    "DEADLINE_UPDATE_BANK_DATA": "Prazo expirado - Atualização de dados bancários",
    "DEADLINE_EXPIRED_CONTRACT_SIGN": "Prazo expirado - Assinatura do contrato",
    "DEADLINE_EXPIRED_ACQUITTANCE_RETURN": "Prazo expirado - Retorno do cliente sobre quitação",
    "DEADLINE_EXPIRED_APPROVE_LOAN": "Prazo expirado - Averbação",
    "DEADLINE_EXPIRED_SEND_IDENTIFICATION_DOCUMENT": "Prazo expirado - Envio de documento de identificação",
    "DEADLINE_EXPIRED_SEND_PAY_STUB": "Prazo expirado - Envio de holerite",
    "DEADLINE_EXPIRED_UPDATE_BANK_DATA": "Prazo expirado - Atualização de dados bancários",
    "DEADLINE_EXPIRED_SEND_PAY_STUB_AND_IDENTIFICATION_DOCUMENT": "Prazo Expirado - Envio de Documento de identificação/Holerite",
  }

export const CAUSES_OF_REJECTION = [
  {
    value: 'CUSTOMER_NO_CONSIGNABLE_MARGIN',
    label: CAUSES_OF_REJECTION_LABELS.CUSTOMER_NO_CONSIGNABLE_MARGIN
  },
  {
    value: 'CUSTOMER_HAS_GIVEN_UP_ON_THE_PROPOSAL_OTHER_REASONS',
    label: CAUSES_OF_REJECTION_LABELS.CUSTOMER_HAS_GIVEN_UP_ON_THE_PROPOSAL_OTHER_REASONS
  },
  {
    value: 'CUSTOMER_HAS_GIVEN_UP_ON_THE_PROPOSAL_OPTED_THE_COMPETITOR',
    label: CAUSES_OF_REJECTION_LABELS.CUSTOMER_HAS_GIVEN_UP_ON_THE_PROPOSAL_OPTED_THE_COMPETITOR
  },
  {
    value: 'INELIGIBLE_CUSTOMER_INSUFFICIENT_HOME_TIME',
    label: CAUSES_OF_REJECTION_LABELS.INELIGIBLE_CUSTOMER_INSUFFICIENT_HOME_TIME
  },
  {
    value: 'EMPLOYEE_AWAY_FROM_WORK',
    label: CAUSES_OF_REJECTION_LABELS.EMPLOYEE_AWAY_FROM_WORK
  },
  {
    value: 'EMPLOYEE_DISMISSED_FROM_WORK',
    label: CAUSES_OF_REJECTION_LABELS.EMPLOYEE_DISMISSED_FROM_WORK
  },
  {
    value: 'CUSTOMER_HAS_GIVEN_UP_ON_THE_PROPOSAL_VALUE_DOESNT_MEET',
    label: CAUSES_OF_REJECTION_LABELS.CUSTOMER_HAS_GIVEN_UP_ON_THE_PROPOSAL_VALUE_DOESNT_MEET
  },
  {
    value: 'INELIGIBLE_CUSTOMER_AGE_RANGE_ABOVE_SIXTY_FIVE',
    label: CAUSES_OF_REJECTION_LABELS.INELIGIBLE_CUSTOMER_AGE_RANGE_ABOVE_SIXTY_FIVE
  },
  {
    value: 'INELIGIBLE_CUSTOMER_AGE_RANGE_ABOVE_SIXTY',
    label: CAUSES_OF_REJECTION_LABELS.INELIGIBLE_CUSTOMER_AGE_RANGE_ABOVE_SIXTY
  },
  {
    value: 'DOESNT_UPDATE_PERSONAL_DATA',
    label: CAUSES_OF_REJECTION_LABELS.DOESNT_UPDATE_PERSONAL_DATA
  },
  {
    value: 'INELIGIBLE_CUSTOMER_ENGINE_ANALYSIS',
    label: CAUSES_OF_REJECTION_LABELS.INELIGIBLE_CUSTOMER_ENGINE_ANALYSIS
  },
  {
    value: 'CUSTOMER_DEBTS_NEGATIVES',
    label: CAUSES_OF_REJECTION_LABELS.CUSTOMER_DEBTS_NEGATIVES
  },
  {
    value: 'DEBT_PURCHASE_REFUSED_BY_THE_CUSTOMER',
    label: CAUSES_OF_REJECTION_LABELS.DEBT_PURCHASE_REFUSED_BY_THE_CUSTOMER
  },
  {
    value: 'PURCHASE_AND_EXCHANGE_DEBT',
    label: CAUSES_OF_REJECTION_LABELS.PURCHASE_AND_EXCHANGE_DEBT
  },
  {
    value: 'PROOF_OF_ADDRESS_MISSING',
    label: CAUSES_OF_REJECTION_LABELS.PROOF_OF_ADDRESS_MISSING
  },
  {
    value: 'PROOF_OF_ADDRESS_INAPPROPRIATE',
    label: CAUSES_OF_REJECTION_LABELS.PROOF_OF_ADDRESS_INAPPROPRIATE
  },
  {
    value: 'BRANCH_WITHOUT_ACTIVE_AGREEMENT',
    label: CAUSES_OF_REJECTION_LABELS.BRANCH_WITHOUT_ACTIVE_AGREEMENT
  },
  {
    value: 'OUTSIDE_THE_RULES_PAY_STUB',
    label: CAUSES_OF_REJECTION_LABELS.OUTSIDE_THE_RULES_PAY_STUB
  },
  {
    value: 'OUTSIDE_THE_RULES_IDENTIFICATION_DOCUMENT',
    label: CAUSES_OF_REJECTION_LABELS.OUTSIDE_THE_RULES_IDENTIFICATION_DOCUMENT
  },
  {
    value: 'OUTSIDE_THE_RULES_PAY_STUB_AND_IDENTIFICATION_DOCUMENT',
    label: CAUSES_OF_REJECTION_LABELS.OUTSIDE_THE_RULES_PAY_STUB_AND_IDENTIFICATION_DOCUMENT
  },
  {
    value: 'INAPPROPRIATE_RG_CHILD_PHOTO',
    label: CAUSES_OF_REJECTION_LABELS.INAPPROPRIATE_RG_CHILD_PHOTO
  },
  {
    value: 'INADEQUATE_MISSING_RG_AND_PROOF_OF_RESIDENCE',
    label: CAUSES_OF_REJECTION_LABELS.INADEQUATE_MISSING_RG_AND_PROOF_OF_RESIDENCE
  },
  {
    value: 'RG_AND_PAY_STUB_INADEQUATE_MISSING',
    label: CAUSES_OF_REJECTION_LABELS.RG_AND_PAY_STUB_INADEQUATE_MISSING
  },
  {
    value: 'RG_PAY_STUB_AND_PROOF_OF_ADDRESS_INADEQUATE_MISSING',
    label: CAUSES_OF_REJECTION_LABELS.RG_PAY_STUB_AND_PROOF_OF_ADDRESS_INADEQUATE_MISSING
  },
  {
    value: 'PAY_STUB_AND_PROOF_OF_ADDRESS_INADEQUATE_MISSING',
    label: CAUSES_OF_REJECTION_LABELS.PAY_STUB_AND_PROOF_OF_ADDRESS_INADEQUATE_MISSING
  },
  {
    value: 'SPECIAL_OFFER_REJECTED',
    label: CAUSES_OF_REJECTION_LABELS.SPECIAL_OFFER_REJECTED
  },
  {
    value: 'DEADLINE_EXPIRED_CONTRACT_SIGN',
    label: CAUSES_OF_REJECTION_LABELS.DEADLINE_EXPIRED_CONTRACT_SIGN
  },
  {
    value: 'DEADLINE_EXPIRED_APPROVE_LOAN',
    label: CAUSES_OF_REJECTION_LABELS.DEADLINE_EXPIRED_APPROVE_LOAN
  },
  {
    value: 'DEADLINE_UPDATE_BANK_DATA',
    label: CAUSES_OF_REJECTION_LABELS.DEADLINE_UPDATE_BANK_DATA
  },
  {
    value: 'DEADLINE_EXPIRED_ACQUITTANCE_RETURN',
    label: CAUSES_OF_REJECTION_LABELS.DEADLINE_EXPIRED_ACQUITTANCE_RETURN
  },
  {
    value: 'DEADLINE_EXPIRED_SEND_PAY_STUB',
    label: CAUSES_OF_REJECTION_LABELS.DEADLINE_EXPIRED_SEND_PAY_STUB
  },  
  {
    value: 'DEADLINE_EXPIRED_SEND_IDENTIFICATION_DOCUMENT',
    label: CAUSES_OF_REJECTION_LABELS.DEADLINE_EXPIRED_SEND_IDENTIFICATION_DOCUMENT
  },
  {
    value: 'DEADLINE_EXPIRED_SEND_PAY_STUB_AND_IDENTIFICATION_DOCUMENT',
    label: CAUSES_OF_REJECTION_LABELS.DEADLINE_EXPIRED_SEND_PAY_STUB_AND_IDENTIFICATION_DOCUMENT
  },
  {
    value: 'OPERATIONAL_PAUSE',
    label: CAUSES_OF_REJECTION_LABELS.OPERATIONAL_PAUSE
  },
  {
    value: 'COMPANY_RULE_EXCEEDED_CONTRACT_LIMIT',
    label: CAUSES_OF_REJECTION_LABELS.COMPANY_RULE_EXCEEDED_CONTRACT_LIMIT
  },
  {
    value: 'COMPANY_RULE_INTERNAL_OBLIGATIONS_NOT_FULFILLED',
    label: CAUSES_OF_REJECTION_LABELS.COMPANY_RULE_INTERNAL_OBLIGATIONS_NOT_FULFILLED
  },
  {
    value: 'REFINANCING_AND_EXCHANGE_AMOUNT_NOT_AVAILABLE',
    label: CAUSES_OF_REJECTION_LABELS.REFINANCING_AND_EXCHANGE_AMOUNT_NOT_AVAILABLE
  },
  {
    value: 'REFINANCING_REFUSED_BY_THE_CUSTOMER',
    label: CAUSES_OF_REJECTION_LABELS.REFINANCING_REFUSED_BY_THE_CUSTOMER
  },
  {
    value: 'REFINANCING_CUSTOMER_NOT_ELIGIBLE',
    label: CAUSES_OF_REJECTION_LABELS.REFINANCING_CUSTOMER_NOT_ELIGIBLE
  },
  {
    value: 'NO_MAXIMUM_AMOUNT_AVAILABLE_FOR_OPERATION',
    label: CAUSES_OF_REJECTION_LABELS.NO_MAXIMUM_AMOUNT_AVAILABLE_FOR_OPERATION
  },
  {
    value: 'PAY_STUB_VALIDATION_NO_OPERATION_LIMIT',
    label: CAUSES_OF_REJECTION_LABELS.PAY_STUB_VALIDATION_NO_OPERATION_LIMIT
  },
  {
    value: 'PLATFORM_TEST',
    label: CAUSES_OF_REJECTION_LABELS.PLATFORM_TEST
  },
]

